import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { requestEmployerAccountSetup } from "../../api/dossier/requestEmployerAccountSetup";
import { requestPackagingAgreement } from "../../api/dossier/requestPackagingAgreement";
import { getMyContact } from "../../api/user/getMyContact";
import BackButton from "../../components/buttons/BackButton";
import APCheckbox from "../../components/form/APCheckbox";
import APForm from "../../components/form/APForm";
import APRadioGroup from "../../components/form/APRadioGroup";
import APTextField from "../../components/form/APTextField";
import PageContainer from "../../components/PageContainer";
import { EmployerUsesAutoUXOption } from "../../types/Dossier";

type Inputs = {
  employerName: string;
  employerUsesAutoUX: EmployerUsesAutoUXOption | null;
  knowsContact: boolean;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  mobile: string;
  contractEmployer: boolean;
};

const employerUsesAutoUXRadioOptions: {
  id: EmployerUsesAutoUXOption;
  label: string;
}[] = [
  { id: "yes", label: "Yes" },
  { id: "no", label: "No" },
  { id: "unsure", label: "Don't know" },
];

const EmployerSetup = () => {
  const { dossierId } = useParams();
  const navigate = useNavigate();
  const { getScopedPageUrl } = useAuth();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });

  if (!dossierId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id param is misisng");
  }

  const {
    mutateAsync: mutateAsyncRequestAccountSetup,
    isLoading: isLoadingRequestAccountSetup,
  } = requestEmployerAccountSetup(dossierId, {
    mutationOptions: {
      successNavPath: getScopedPageUrl(
        "dossierActionConfirmation",
        dossierId,
        "100000000"
      ),
    },
  });

  const {
    mutateAsync: mutateAsyncRequestPackagingAgreement,
    isLoading: isLoadingRequestPackagingAgreement,
  } = requestPackagingAgreement(dossierId, {
    mutationOptions: {
      successNavPath: getScopedPageUrl(
        "dossierActionConfirmation",
        dossierId,
        "100000000"
      ),
    },
  });

  const watchEmployerUsesAutoUX = watch("employerUsesAutoUX");
  const watchKnowsContact = watch("knowsContact");

  const clearContactDetails = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("jobTitle", "");
    setValue("email", "");
    setValue("mobile", "");
  };

  // If user selects "No" or "Don't know" then we should clear all fields underneath
  useEffect(() => {
    if (watchEmployerUsesAutoUX === "yes") {
      setValue("knowsContact", false);
      clearContactDetails();
      setValue("contractEmployer", false);
    }
  }, [watchEmployerUsesAutoUX]);

  // If user unchecks that they know the contact then we should clear the fields underneath
  useEffect(() => {
    if (!watchKnowsContact) {
      clearContactDetails();
    }
  }, [watchKnowsContact]);

  const dossier = getDossier(dossierId);

  const contact = getMyContact();

  if (dossier.isError) {
    throw dossier.error;
  }

  if (contact.isError) {
    throw contact.error;
  }

  const isLoading = dossier.isLoading || contact.isLoading;
  const isSuccess = dossier.isSuccess && contact.isSuccess;

  const accountAuthorised =
    isSuccess &&
    contact.data.parentAccount &&
    contact.data.parentAccount.accountAuthorised;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (accountAuthorised) {
      mutateAsyncRequestPackagingAgreement();
    } else {
      mutateAsyncRequestAccountSetup({
        employerName: data.employerName,
        employerUsesAutoUX: data.employerUsesAutoUX || "unsure",
        firstName: data.firstName,
        lastName: data.lastName,
        jobTitle: data.jobTitle,
        email: data.email,
        mobile: data.mobile,
      });
    }
  };

  // If package agreement is already requested then redirect to finance application
  useEffect(() => {
    if (isSuccess && dossier.data.opportunity) {
      if (
        dossier.data.opportunity.packagingAgreementRequested ||
        dossier.data.opportunity.driverRequestedEmployerSetup
      ) {
        navigate(getScopedPageUrl("dossierFinanceApplication", dossierId), {
          replace: true,
        });
      } else if (dossier.data.opportunity.newAccountName) {
        setValue("employerName", dossier.data.opportunity.newAccountName);
      }
    }
  }, [isSuccess]);

  return (
    <PageContainer loading={isLoading} title="Employer Setup">
      {isSuccess && (
        <Stack spacing={2}>
          <Typography variant="labelLarge">
            {accountAuthorised
              ? "To get you into your car, we need to get approval from your employer."
              : "To get you into your car, we need to setup an account with your employer."}
          </Typography>
          <APForm
            onSubmit={handleSubmit(onSubmit)}
            submitText="Next"
            submitIcon={<PlayArrowOutlined />}
            leftButton={<BackButton />}
            isLoading={
              isLoadingRequestAccountSetup || isLoadingRequestPackagingAgreement
            }
            isError={Object.keys(errors).length > 0}
          >
            {!accountAuthorised && (
              <Stack spacing={2}>
                <APTextField<Inputs>
                  name="employerName"
                  label="Employer Name"
                  control={control}
                  validations={{
                    required: true,
                    maxLength: 100,
                  }}
                  errors={errors}
                  defaultValue=""
                />

                <APRadioGroup
                  name="employerUsesAutoUX"
                  label="Employer already uses Auto-UX?"
                  validations={{ required: true }}
                  errors={errors}
                  control={control}
                  defaultValue={null}
                  options={employerUsesAutoUXRadioOptions}
                />
              </Stack>
            )}

            {(watchEmployerUsesAutoUX === "no" ||
              watchEmployerUsesAutoUX === "unsure") && (
              <Stack spacing={2}>
                <Box>
                  <Typography variant="labelLarge">
                    To help fast track the process you can provide a HR or
                    Finance contact.
                  </Typography>
                  <Box sx={{ mt: 2, ml: 2 }}>
                    <APCheckbox
                      name="knowsContact"
                      control={control}
                      label="I know an HR or Finance contract at my employer"
                      defaultValue={false}
                    />
                  </Box>
                </Box>

                {watchKnowsContact && (
                  <Stack spacing={2}>
                    <Typography variant="labelLarge">
                      Please provide the details of an HR, Finance or Management
                      person we can contact to set this up for you. This is
                      optional, but will speed up the process.
                    </Typography>

                    <APTextField<Inputs>
                      name="firstName"
                      label="First Name"
                      control={control}
                      validations={{
                        maxLength: 100,
                      }}
                      errors={errors}
                      defaultValue=""
                      helperText="Optional"
                    />

                    <APTextField<Inputs>
                      name="lastName"
                      label="Last Name"
                      control={control}
                      validations={{
                        maxLength: 100,
                      }}
                      errors={errors}
                      defaultValue=""
                      helperText="Optional"
                    />

                    <APTextField<Inputs>
                      name="jobTitle"
                      label="Job Title"
                      control={control}
                      validations={{
                        maxLength: 100,
                      }}
                      errors={errors}
                      defaultValue=""
                      helperText="Optional"
                    />

                    <APTextField<Inputs>
                      name="email"
                      label="Email"
                      control={control}
                      validations={{
                        maxLength: 100,
                        formatValidation:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      }}
                      errors={errors}
                      defaultValue=""
                      helperText="Optional"
                    />

                    <APTextField<Inputs>
                      name="mobile"
                      label="Mobile"
                      control={control}
                      validations={{
                        minLength: 10,
                        maxLength: 12,
                        formatValidation: /^[0-9\s()+-]+$/,
                      }}
                      errors={errors}
                      defaultValue=""
                      helperText="Optional"
                    />

                    <Typography variant="labelLarge">
                      We recommend you advise the person so that they expect our
                      call.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}

            <Box>
              <Box sx={{ ml: 2 }}>
                <APCheckbox
                  name="contractEmployer"
                  control={control}
                  label={
                    accountAuthorised
                      ? "Please contact my employer to get approved"
                      : "Please contact my employer to setup Auto-UX"
                  }
                  defaultValue={false}
                  validations={{ required: true }}
                  errors={errors}
                />
              </Box>
            </Box>
          </APForm>
        </Stack>
      )}
    </PageContainer>
  );
};

export default EmployerSetup;
