import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { getEmptyDossierStatus } from "../api/dossier/getEmptyDossierStatus";
import { getMyContact } from "../api/user/getMyContact";
import { getMyUser } from "../api/user/getMyUser";
import { qualifyLead } from "../api/user/qualifyLead";
import AutoPilotAlert from "../components/Alert";
import APAccordion from "../components/APAccordion";
import APAutoComplete, {
  AutoCompleteOption,
} from "../components/form/APAutoComplete";
import APForm from "../components/form/APForm";
import APTextField from "../components/form/APTextField";
import PageContainer from "../components/PageContainer";
import DossierStatusStepper from "../dossier/components/DossierStatusStepper";
import { DEFAULT_ACCOUNT } from "../types/Contact";
import { leadSourceOptions } from "../types/LeadSourceOptions";

type Inputs = {
  mobile: string;
  employerName: string;
  source: AutoCompleteOption | null;
};

const UserInfo = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });

  const user = getMyUser();
  const contact = getMyContact();
  const dossierStatus = getEmptyDossierStatus();

  if (user.isError) {
    throw user.error;
  }

  if (contact.isError) {
    throw contact.error;
  }

  if (dossierStatus.isError) {
    throw dossierStatus.error;
  }

  const { mutateAsync: mutateQualifyAsync, isLoading: isQualifyLoading } =
    qualifyLead({ mutationOptions: { successNavPath: "/dossiers" } });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    mutateQualifyAsync({
      mobilePhone: data.mobile,
      companyName: data.employerName,
      leadSourceCode: data.source?.id || undefined,
    });
  };

  const isSuccess = contact.isSuccess && dossierStatus.isSuccess;
  const isLoading = contact.isLoading && dossierStatus.isLoading;
  const isUpdating = isQualifyLoading;

  useEffect(() => {
    if (isSuccess) {
      if (contact.data) {
        if (contact.data.mobilePhone) {
          setValue("mobile", contact.data.mobilePhone);
        }

        if (
          contact.data.parentAccountId &&
          contact.data.parentAccountEmployerName &&
          contact.data.parentAccountId !== DEFAULT_ACCOUNT
        ) {
          setValue("employerName", contact.data.parentAccountEmployerName);
        }
      } else if (user.data) {
        if (
          user.data.leadAccountId &&
          user.data.leadAccountName &&
          user.data.leadAccountId !== DEFAULT_ACCOUNT
        ) {
          setValue("employerName", user.data.leadAccountName);
        }
      }
    }
  }, [isSuccess]);

  return (
    <PageContainer loading={isLoading} title="User Info">
      {isSuccess && (
        <APForm
          onSubmit={handleSubmit(onSubmit)}
          submitText="Next"
          submitIcon={<PlayArrowOutlined />}
          isLoading={isUpdating}
          isError={Object.keys(errors).length > 0}
        >
          <APTextField<Inputs>
            name="mobile"
            label="Mobile"
            control={control}
            validations={{
              required: true,
              minLength: 10,
              maxLength: 12,
              formatValidation: /^[0-9\s()+-]+$/,
            }}
            errors={errors}
            defaultValue=""
          />
          <APTextField<Inputs>
            name="employerName"
            label="Employer Name"
            control={control}
            validations={{
              required: true,
              maxLength: 100,
            }}
            errors={errors}
            defaultValue=""
          />
          <APAutoComplete
            name="source"
            label="How did you hear about Auto-UX?"
            options={leadSourceOptions}
            control={control}
            validations={{}}
            errors={errors}
            defaultValue={null}
          />
          <APAccordion title="Your Progress" defaultExpanded>
            <Box sx={{ mt: 1, ml: 1 }}>
              <DossierStatusStepper
                dossierStatus={dossierStatus.data}
              ></DossierStatusStepper>
            </Box>
          </APAccordion>
          <Box>
            <AutoPilotAlert
              severity="info"
              message="Click next to build your quote!"
              asHTML={true}
            />
          </Box>
        </APForm>
      )}
    </PageContainer>
  );
};

export default UserInfo;
