import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { createMyBankAccount } from "../../api/user/createMyBankAccount";
import { getMyBankAccount } from "../../api/user/getMyBankAccount";
import { getMyContact } from "../../api/user/getMyContact";
import BackButton from "../../components/buttons/BackButton";
import APCheckbox from "../../components/form/APCheckbox";
import APForm from "../../components/form/APForm";
import APTextField from "../../components/form/APTextField";
import PageContainer from "../../components/PageContainer";
import { downloadFile } from "../../helpers/download";

type Inputs = {
  financialInstitution: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
  agree: boolean;
};

const DirectDebit = () => {
  const { dossierId } = useParams();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const { fetchWithAuth, getClientIP } = useApi();
  const { getScopedPageUrl } = useAuth();
  const shouldLoadDossier = !!dossierId;
  const shouldLoadBankAccount = !dossierId;

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    createMyBankAccount(dossierId, {
      mutationOptions: {
        successNavPath: dossierId
          ? `/dossiers/${dossierId}/action-confirmation/100000002`
          : "/dossiers",
      },
    });

  const contact = getMyContact();
  const dossier = getDossier(dossierId || "", { disabled: !shouldLoadDossier });
  const bankAccount = getMyBankAccount({ disabled: !shouldLoadBankAccount });

  if (contact.isError) {
    throw contact.error;
  }

  if (dossier.isError) {
    throw dossier.error;
  }

  if (bankAccount.isError) {
    throw bankAccount.error;
  }

  const isLoading =
    contact.isLoading || dossier.isLoading || bankAccount.isLoading;
  const isSuccess =
    contact.isSuccess &&
    (!shouldLoadDossier || dossier.isSuccess) &&
    (!shouldLoadBankAccount || bankAccount.isSuccess);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { ip } = await getClientIP();

    mutateAsyncUpdate({
      financialInstitution: data.financialInstitution,
      accountName: data.accountName,
      bsb: data.bsb,
      accountNumber: data.accountNumber,
      driverIp: ip,
      driverDevice: navigator.userAgent,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      const hasBankAccounts = contact.data.activeBankAccountCount > 0;

      // If contact already has an active bank account then redirect to no next action page
      if (hasBankAccounts && shouldLoadDossier) {
        navigate(
          getScopedPageUrl("dossierActionConfirmation", dossierId, "not-ready"),
          {
            replace: true,
          }
        );
      } else {
        setNavigationCheck(true);
      }
    }
  }, [isSuccess]);

  const downloadPDF = async () => {
    const pdf = await fetchWithAuth<Blob>("getDirectDebitTermsPDF", dossierId, {
      responseOptions: { responseType: "blob" },
    });

    downloadFile(pdf, "DirectDebitTerms.pdf");
  };

  return (
    <PageContainer
      loading={isLoading || !navigationCheckComplete}
      title="Bank Details"
    >
      {isSuccess && (
        <Stack spacing={2}>
          <Typography variant="labelLarge">
            We need your bank details to reimburse you for vehicle expenses you
            may incur.
            <br />
            <br />
            We also setup a direct debit to be used in the unlikely event that
            we cannot recover vehicle expenses from your employer.
          </Typography>
          {bankAccount.data && (
            <Typography
              variant="titleMedium"
              color="secondary"
              align="center"
              sx={{ px: 1 }}
            >
              Click Save to replace existing account
              <br />
              BSB: {bankAccount.data.bsb.replace("-", "")} Account:{" "}
              {bankAccount.data.accountNumber}
            </Typography>
          )}
          <APForm
            onSubmit={handleSubmit(onSubmit)}
            submitText="Next"
            submitIcon={<PlayArrowOutlined />}
            leftButton={<BackButton />}
            isLoading={isLoadingUpdate}
            isError={Object.keys(errors).length > 0}
          >
            <APTextField<Inputs>
              name="financialInstitution"
              label="Financial Institution"
              control={control}
              validations={{
                required: true,
                maxLength: 100,
              }}
              errors={errors}
              defaultValue=""
            />

            <APTextField<Inputs>
              name="accountName"
              label="Account Name"
              control={control}
              validations={{
                required: true,
                maxLength: 100,
              }}
              errors={errors}
              defaultValue=""
            />

            <APTextField<Inputs>
              name="bsb"
              label="BSB"
              control={control}
              validations={{
                required: true,
                formatValidation: /[0-9]{3}-?[0-9]{3}/,
              }}
              errors={errors}
              defaultValue=""
              placeholder="xxx-xxx"
            />

            <APTextField<Inputs>
              name="accountNumber"
              label="Account Number"
              control={control}
              validations={{
                required: true,
                maxLength: 20,
                formatValidation: /^[0-9]+$/,
              }}
              errors={errors}
              defaultValue=""
            />

            <Box>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={() => downloadPDF()}
              >
                Direct Debit Terms
              </Button>
            </Box>

            <Box>
              <Box>
                <APCheckbox
                  name="agree"
                  control={control}
                  label="I agree to the terms of the Direct Debit."
                  defaultValue={false}
                  validations={{ required: true }}
                  errors={errors}
                />
              </Box>
            </Box>
          </APForm>
        </Stack>
      )}
    </PageContainer>
  );
};

export default DirectDebit;
