import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type ApproveDriverPayload = {
  dossierId: string;
  employerIp: string;
  employerDevice: string;
  employerId?: string;
  payrollCycleId?: string;
  employeeId?: string;
};

export const approveDriver = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: ApproveDriverPayload): Promise<void> => {
      return fetchWithAuth<void>("approveDriver", payload.dossierId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["dossiers-for-approval"]]),
  });
};
