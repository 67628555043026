import { useMutation } from "react-query";

import { QuotePayPeriod } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type UpdateQuotePayload = {
  dossierId?: string;
  name?: string;
  payPeriod?: QuotePayPeriod;
  state?: string;
  variantId?: string;
  term?: number;
  annualKilometres?: number;
  salary?: number;
  businessUsage?: number;
  listPrice?: number;
  optionalEquipment?: string[];
  referralCode?: string;
};

export const updateQuote = (quoteId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: UpdateQuotePayload): Promise<string> => {
      return fetchWithAuth<string>("updateQuote", quoteId, {
        requestOptions: { method: "PATCH", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: (data, error, payload) => {
      const invalidateList = [["quote", quoteId]];

      if (payload.dossierId) {
        invalidateList.push(
          ["quotes", payload.dossierId],
          ["dossier", payload.dossierId]
        );
      }
      defaultOnMutateSettled(invalidateList);
    },
  });
};
