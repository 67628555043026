import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseQueryResult } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { createQuote } from "../../api/quote/createQuote";
import { getQuote } from "../../api/quote/getQuote";
import { updateQuote, UpdateQuotePayload } from "../../api/quote/updateQuote";
import { getVehicleMakes } from "../../api/vehicle/getVehicleMakes";
import { getVehicleModels } from "../../api/vehicle/getVehicleModels";
import { getVehicleModelYears } from "../../api/vehicle/getVehicleModelYears";
import { getVehicleVariants } from "../../api/vehicle/getVehicleVariants";
import CatchEButton from "../../components/buttons/CatchEButton";
import APAutoComplete, {
  AutoCompleteOption,
} from "../../components/form/APAutoComplete";
import APForm from "../../components/form/APForm";
import APToggleGroup from "../../components/form/APFormToggleGroup";
import APTextField from "../../components/form/APTextField";
import PageContainer from "../../components/PageContainer";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import { buildAutoCompleteOptions } from "../../helpers/buildOptions";
import { CalculatedQuote } from "../../types/Quote";
import { termOptions } from "../../types/QuoteTermOptions";
import { stateOptions } from "../../types/State";

export enum QuoteCreateAction {
  Create = "Create",
  Clone = "Clone",
  Edit = "Edit",
}

type Inputs = {
  vehicleMake: AutoCompleteOption | null;
  vehicleModel: AutoCompleteOption | null;
  vehicleYear: AutoCompleteOption | null;
  vehicleVariant: AutoCompleteOption | null;
  state: AutoCompleteOption | null;
  income: string;
  term: string;
  kilometres: string;
  businessUsage: string;
  listPrice: string;
  referralCode: string;
};

export type CreateQuoteSubmitAction = "next" | "personalise";
const CreateQuote = ({ action }: { action: QuoteCreateAction }) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dossierId, quoteId } = useParams();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [showReferralCodeField, setShowReferralCodeField] = useState(false);
  const { inAppScope, getScopedPageUrl } = useAuth();

  const limitedFields =
    !!searchParams.get("limitedFields") &&
    searchParams.get("limitedFields") === "true";

  const onNavigate = (
    quoteId: string,
    submitAction: CreateQuoteSubmitAction
  ) => {
    switch (submitAction) {
      case "next":
        navigate(getScopedPageUrl("viewQuote", quoteId));
        break;
      case "personalise":
        navigate(getScopedPageUrl("personaliseQuote", quoteId));
        break;
    }
  };

  let quote: UseQueryResult<CalculatedQuote, unknown> | null = null;

  switch (action) {
    case QuoteCreateAction.Create:
      if (!navigationCheckComplete) {
        setNavigationCheck(true);
      }
      break;
    case QuoteCreateAction.Clone:
    case QuoteCreateAction.Edit:
      if (!quoteId) {
        // TODO: improve client side error messaging
        throw new Error("quote id param is misisng");
      }

      quote = getQuote(quoteId);

      useEffect(() => {
        if (quote?.isSuccess) {
          // If the quote is locked and the action is edit then redirect to the quote page
          if (quote.data.isLocked && action === QuoteCreateAction.Edit) {
            navigate(getScopedPageUrl("viewQuote", quoteId), {
              replace: true,
            });
          } else {
            setValue("vehicleMake", {
              id: quote.data.vehicle.makeId,
              label: `${quote.data.vehicle.make}`,
            });
            setValue("vehicleModel", {
              id: quote.data.vehicle.modelId,
              label: `${quote.data.vehicle.model}`,
            });
            setValue("vehicleYear", {
              id: quote.data.vehicle.year,
              label: quote.data.vehicle.year,
            });
            setValue("vehicleVariant", {
              id: quote.data.vehicle.variantId,
              label: quote.data.vehicle.variantLongName,
            });
            setValue("state", {
              id: quote.data.state,
              label: quote.data.state,
            });
            setValue("income", `${quote.data.annualSalary}`);
            setValue("term", `${quote.data.term}`);
            setValue("kilometres", `${quote.data.annualKm}`);
            setValue("businessUsage", `${quote.data.businessUsage}`);
            setValue("listPrice", `${quote.data.listPrice}`);

            setNavigationCheck(true);
          }
        }
      }, [quote.isSuccess]);

      if (quote.isError) {
        throw quote.error;
      }
      break;
  }

  let _dossierId: string = "";

  if (action === QuoteCreateAction.Create) {
    if (!dossierId) {
      // TODO: improve client side error messaging
      throw new Error("dossier id param is misisng");
    }
    _dossierId = dossierId;
  } else if (
    action === QuoteCreateAction.Clone ||
    action === QuoteCreateAction.Edit
  ) {
    _dossierId = quote?.data?.dossierId || "";
  }

  const dossier = getDossier(_dossierId, { disabled: !_dossierId });

  if (dossier.isError) {
    throw dossier.error;
  }

  const { mutateAsync, isLoading: isLoadingMutate } = createQuote(_dossierId);

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdateMutate } =
    updateQuote(quoteId || "");

  // Sets up a field to reset if it's parent field changes
  // Also returns the watched parent field to use for controlling visibility of the fields
  // in the form
  const setCascadeReset = (
    parentField: keyof Pick<
      Inputs,
      "vehicleMake" | "vehicleModel" | "vehicleYear"
    >,
    childField: keyof Pick<
      Inputs,
      "vehicleModel" | "vehicleYear" | "vehicleVariant"
    >
  ) => {
    const watchField = watch(parentField);
    const previousValue = useRef(watchField);

    useEffect(() => {
      const previous = previousValue.current && previousValue.current.id;
      const current = watchField && watchField.id;

      if (!!previous && previous !== current) {
        setValue(childField, null);
      }

      previousValue.current = watchField;
    }, [watchField, setValue]);

    return watchField;
  };

  const response = getVehicleMakes();

  if (response.isError) {
    throw response.error;
  }

  const onSubmit = async (
    data: Inputs,
    submitAction: CreateQuoteSubmitAction
  ) => {
    if (!quote || quote.isSuccess) {
      if (!data.state || !data.vehicleVariant) {
        // TODO: better error
        throw new Error("invalid");
      }

      if (
        action === QuoteCreateAction.Create ||
        action === QuoteCreateAction.Clone
      ) {
        await mutateAsync(
          {
            state: data.state.id,
            variantId: data.vehicleVariant.id,
            salary: parseFloat(data.income),
            term: parseInt(data.term),
            annualKilometres: parseInt(data.kilometres),
            businessUsage: limitedFields ? 0 : parseInt(data.businessUsage),
            /* AP-310: Hide list price field */
            listPrice:
              inAppScope("internal") && data.listPrice
                ? parseInt(data.listPrice)
                : undefined,
            referralCode: data.referralCode || undefined,
          },
          { onSuccess: (quoteId: string) => onNavigate(quoteId, submitAction) }
        );
      } else if (action === QuoteCreateAction.Edit && quote && quoteId) {
        const updatePayload: UpdateQuotePayload = {
          dossierId: _dossierId,
          referralCode: data.referralCode || undefined,
        };

        if (data.vehicleVariant.id !== quote.data.vehicle.variantId) {
          updatePayload.variantId = data.vehicleVariant.id;
        }
        if (data.state.id !== quote.data.state) {
          updatePayload.state = data.state.id;
        }
        if (data.term !== `${quote.data.term}`) {
          updatePayload.term = parseInt(data.term);
        }
        if (data.kilometres !== `${quote.data.annualKm}`) {
          updatePayload.annualKilometres = parseInt(data.kilometres);
        }
        if (data.income !== `${quote.data.annualSalary}`) {
          updatePayload.salary = parseFloat(data.income);
        }
        if (data.businessUsage !== `${quote.data.businessUsage}`) {
          updatePayload.businessUsage = parseInt(data.businessUsage);
        }
        if (data.listPrice !== `${quote.data.listPrice}`) {
          updatePayload.listPrice = parseFloat(data.listPrice);
        }

        if (Object.keys(updatePayload).length > 0) {
          await mutateAsyncUpdate(updatePayload);
        }
        onNavigate(quoteId, submitAction);
      }
    }
  };

  const onNext: SubmitHandler<Inputs> = async (data) => {
    onSubmit(data, "next");
  };

  const onPersonalise: SubmitHandler<Inputs> = async (data) => {
    onSubmit(data, "personalise");
  };

  const onReferralLinkClick = () => {
    setShowReferralCodeField(true);
  };

  const watchVehicleMake = setCascadeReset("vehicleMake", "vehicleModel");
  const watchVehicleModel = setCascadeReset("vehicleModel", "vehicleYear");
  const watchVehicleYear = setCascadeReset("vehicleYear", "vehicleVariant");
  const watchVehicleVariant = watch("vehicleVariant");

  useEffect(() => {
    const previous = quote && quote.isSuccess && quote.data.vehicle.variantId;
    const current = watchVehicleVariant && watchVehicleVariant.id;

    if (!!current && previous !== current) {
      setValue("listPrice", "");
    }
  }, [watchVehicleVariant]);

  const vehicleModels = getVehicleModels(watchVehicleMake?.id || null);
  const vehicleModelYears = getVehicleModelYears(watchVehicleModel?.id || null);

  const vehicleModelVariants = getVehicleVariants(
    watchVehicleMake?.id || null,
    watchVehicleModel?.id || null,
    watchVehicleYear?.id || null
  );

  const isLoading =
    response.isFetching ||
    (!!quote && quote.isFetching) ||
    !_dossierId ||
    dossier.isFetching;
  const isSuccess =
    response.isSuccess && (!quote || quote.isSuccess) && dossier.isSuccess;
  const showReferralCodeLink =
    !showReferralCodeField &&
    dossier?.data?.opportunity &&
    !dossier.data.opportunity.referralCode;

  return (
    <PageContainer
      loading={isLoading || !navigationCheckComplete}
      title="Select Vehicle"
    >
      {isSuccess && navigationCheckComplete && (
        <>
          <Typography variant="labelLarge" color="onSurface.main" gutterBottom>
            This info is needed for your quote.
          </Typography>
          <APForm
            onSubmit={handleSubmit(onNext)}
            submitText="View Quote"
            submitIcon={<PlayArrowOutlined />}
            isLoading={isLoadingMutate || isLoadingUpdateMutate}
            isError={Object.keys(errors).length > 0}
            additionalButton={
              <Box sx={{ display: "flex" }}>
                {quote?.data && (
                  <Box sx={{ mr: { md: 2, sm: 0 } }}>
                    <RestrictedAppScope scope="internal">
                      <CatchEButton url={quote.data.catchEQuoteUrl} />
                    </RestrictedAppScope>
                  </Box>
                )}
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  startIcon={<DriveEtaOutlinedIcon />}
                  sx={{ color: "onPrimary.main" }}
                  onClick={handleSubmit(onPersonalise)}
                >
                  Personalise
                </Button>
              </Box>
            }
          >
            <APAutoComplete
              name="vehicleMake"
              label="Vehicle Make"
              options={buildAutoCompleteOptions(response.data, "name", "id")}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
            />

            {watchVehicleMake && vehicleModels.isSuccess && (
              <APAutoComplete
                name="vehicleModel"
                label="Vehicle Model"
                options={buildAutoCompleteOptions(
                  vehicleModels.data,
                  "name",
                  "id"
                )}
                control={control}
                validations={{ required: true }}
                errors={errors}
                defaultValue={null}
              />
            )}

            {watchVehicleModel && vehicleModelYears.isSuccess && (
              <APAutoComplete
                name="vehicleYear"
                label="Vehicle Year"
                options={buildAutoCompleteOptions(
                  vehicleModelYears.data.map((year) => {
                    return { id: `${year}`, name: `${year}` };
                  }),
                  "name",
                  "id"
                )}
                control={control}
                validations={{ required: true }}
                errors={errors}
                defaultValue={null}
              />
            )}

            {watchVehicleYear && vehicleModelVariants.isSuccess && (
              <APAutoComplete
                name="vehicleVariant"
                label="Vehicle Variant"
                options={buildAutoCompleteOptions(
                  vehicleModelVariants.data,
                  "name",
                  "id"
                )}
                control={control}
                validations={{ required: true }}
                errors={errors}
                defaultValue={null}
              />
            )}
            <APAutoComplete
              name="state"
              label="State"
              options={stateOptions}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={""}
            />
            <APTextField<Inputs>
              name="income"
              label="Annual Salary (ex super)"
              control={control}
              validations={{
                required: true,
                formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
              }}
              errors={errors}
              defaultValue={""}
              startAdornment="$"
              placeholder="xxxxxxx.xx"
              formatNumber={true}
            />
            <APToggleGroup
              name="term"
              control={control}
              options={termOptions}
              defaultValue="60"
            />
            <APTextField<Inputs>
              name="kilometres"
              label="Annual Km"
              control={control}
              validations={{
                required: true,
                formatValidation: /^[0-9]+$/,
              }}
              errors={errors}
              defaultValue="10000"
              endAdornment="km"
              placeholder="xxxxx"
              helperText="Number of kms driven p.a."
            />
            {!limitedFields && (
              <APTextField<Inputs>
                name="businessUsage"
                label="Business Usage (if any)"
                control={control}
                validations={{
                  maxLength: 2,
                  formatValidation: /^[0-9]+$/,
                }}
                errors={errors}
                defaultValue=""
                endAdornment="%"
                placeholder="xx"
                helperText="Business logbook required"
              />
            )}
            <RestrictedAppScope scope="external">
              {showReferralCodeField && (
                <APTextField<Inputs>
                  name="referralCode"
                  label="Referral Code"
                  control={control}
                  validations={{
                    maxLength: 6,
                    minLength: 6,
                  }}
                  errors={errors}
                  defaultValue=""
                />
              )}
            </RestrictedAppScope>
            <RestrictedAppScope scope="internal">
              {watchVehicleVariant && (
                <APTextField<Inputs>
                  name="listPrice"
                  label="Vehicle List Cost"
                  control={control}
                  validations={{
                    formatValidation: /^[0-9]+(\.[0-9][0-9]?)?$/,
                  }}
                  errors={errors}
                  defaultValue=""
                  startAdornment="$"
                  placeholder="xxxxxxx.xx"
                  formatNumber={true}
                />
              )}
            </RestrictedAppScope>
          </APForm>
          <RestrictedAppScope scope="external">
            {showReferralCodeLink && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  variant="bodySmall"
                  color="primary"
                  onClick={onReferralLinkClick}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  I have a referral code
                </Typography>
              </Box>
            )}
          </RestrictedAppScope>
        </>
      )}
    </PageContainer>
  );
};

export default CreateQuote;
