import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type CreateQuotePayload = {
  state: string;
  variantId: string;
  term: number;
  annualKilometres: number;
  salary: number;
  businessUsage: number;
  listPrice?: number;
  referralCode?: string;
};

export const createQuote = (
  dossierId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: CreateQuotePayload): Promise<string> => {
      return fetchWithAuth<string>("createQuote", dossierId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () =>
      defaultOnMutateSettled([
        ["quotes", dossierId],
        ["dossier", dossierId],
      ]),
  });
};
