import querystring from "querystring";
import React, { createContext, ReactNode, useContext } from "react";

import { ErrorCode } from "../types/Error";
import { ApiDirectory, ApiDirectoryEntryName } from "./ApiDirectory";

const REQUEST_DETAILS_HEADER = "Request-Details";

export type ErrorCause = {
  errorCode: ErrorCode;
};

export async function handleErrorResponse(response: Response) {
  const requestDetails = response.headers.get(REQUEST_DETAILS_HEADER);
  let error;
  let errorCause: ErrorCause | undefined = undefined;

  try {
    error = await response.text();

    const parsedError = JSON.parse(error as string);
    errorCause = { errorCode: parsedError.errorCode };
  } catch (err) {
    error = err;
  }

  throw new Error(
    `${JSON.stringify(error)}; requestDetails: ${requestDetails}`,
    { cause: errorCause }
  );
}

export type FetchOptions = {
  requestOptions?: RequestInit & { excludeContentTypeHeader?: boolean };
  queryParams?: Record<string, string>;
  responseOptions?: {
    responseType?: "json" | "blob" | "fileUrl";
  };
};

export type ClientIPData = {
  ip: string;
};

// Define the context with a fetch function
const ApiContext = createContext<{
  fetchWithAuth: <T>(
    directoryEntry: ApiDirectoryEntryName,
    entityId?: string,
    options?: FetchOptions
  ) => Promise<T>;
  getClientIP: () => Promise<ClientIPData>;
}>({
  fetchWithAuth: () => Promise.reject("API client not configured"),
  getClientIP: () => Promise.reject("API client not configured"),
});

// Custom hook to use the API context
export const useApi = () => {
  return useContext(ApiContext);
};

function attachQueryString(path: string, queryString: string | null) {
  return queryString ? `${path}?${queryString}` : path;
}

// API Provider component
export const ApiProvider: React.FC<{
  token: string;
  baseUrl: string;
  apiDirectory: ApiDirectory;
  children: ReactNode;
}> = ({ token, baseUrl, apiDirectory, children }) => {
  const fetchWithAuth = async (
    directoryEntry: ApiDirectoryEntryName,
    entityId?: string,
    options: FetchOptions = {}
  ) => {
    const apiEntry = apiDirectory[directoryEntry];

    if (apiEntry) {
      let path = apiEntry(entityId);

      if (options.queryParams) {
        const queryString = querystring.stringify(options.queryParams);
        path = attachQueryString(path, queryString);
      }

      const defaultOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(!options.requestOptions?.excludeContentTypeHeader && {
            ["Content-Type"]: "application/json",
          }),
        },
        ...options.requestOptions,
      };
      const response = await fetch(`${baseUrl}${path}`, defaultOptions);

      if (!response.ok) {
        await handleErrorResponse(response);
      }

      const responseType =
        (options.responseOptions && options.responseOptions.responseType) ||
        "json";

      switch (responseType) {
        case "json":
          return response.json();
        case "blob":
          return response.blob();
        case "fileUrl": {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);

          return imageUrl;
        }
      }
    } else {
      throw Error(
        `No configured endpoint, entry: ${directoryEntry}, entityId: ${entityId}`
      );
    }
  };

  const getClientIP = async (): Promise<ClientIPData> => {
    const response = await fetch("https://api.ipify.org?format=json");

    if (!response.ok) {
      await handleErrorResponse(response);
    }

    return response.json();
  };

  return (
    <ApiContext.Provider value={{ fetchWithAuth, getClientIP }}>
      {children}
    </ApiContext.Provider>
  );
};
