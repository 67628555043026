import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { getDossierStatus } from "../../api/dossier/getDossierStatus";
import AutoPilotAlert from "../../components/Alert";
import PageContainer from "../../components/PageContainer";
import { buildNextActionRoute, DossierPhaseEvent } from "../../types/Dossier";
import DossierStatusStepper from "../components/DossierStatusStepper";

type EventInfo = {
  title: string;
  description: string;
};

const ActionConfirmation = () => {
  const navigate = useNavigate();
  const { dossierId, eventId } = useParams();
  const { getScopedPageUrl } = useAuth();
  const [eventDetails, setEventDetails] = useState(null as EventInfo | null);
  if (!dossierId || !eventId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id or next action param are misisng");
  }

  const dossier = getDossier(dossierId);

  const dossierStatus = getDossierStatus(dossierId);

  if (dossier.isError) {
    throw dossier.error;
  }

  if (dossierStatus.isError) {
    throw dossierStatus.error;
  }

  const isLoading = dossier.isLoading || dossierStatus.isLoading;
  const isSuccess = dossier.isSuccess && dossierStatus.isSuccess;

  // Determine whether the user should see this confirmation or if they should be
  // redirected elsewhere
  useEffect(() => {
    if (isSuccess) {
      const events = dossierStatus.data.dossierPhases.reduce(
        (acc: DossierPhaseEvent[], phase) => {
          return acc.concat(phase.events);
        },
        []
      );
      const selectedEvent = events.find(
        (event) => `${event.eventId}` === eventId
      );

      if (
        selectedEvent /* TODO: fix timing issue && selectedEvent.completed*/
      ) {
        setEventDetails({
          title: selectedEvent.eventName,
          description: selectedEvent.completionText,
        });
      } else if (eventId === "not-ready") {
        setEventDetails({
          title: "Well Done!",
          description:
            "You have completed everything we need at this stage, and we’re busy making things happen in the background.<br/><br/>We will be in contact with next steps.<br/>Want to speak to us sooner? Call us.",
        });
      } else {
        // If the event id does not match any event or the event has not been completed,
        // then we redirect to home
        navigate(getScopedPageUrl("viewDossiers"), {
          replace: true,
        });
      }
    }
  }, [isSuccess]);

  const nextAction = isSuccess ? dossierStatus.data.nextDriverAction : null;
  const nextIsHome =
    !nextAction ||
    nextAction === "no-action-required" ||
    eventId === "not-ready";

  const onNext = async () => {
    const route = nextIsHome
      ? getScopedPageUrl("viewDossiers")
      : buildNextActionRoute(dossierId, nextAction);

    navigate(route);
  };

  return (
    <PageContainer
      title={eventDetails ? eventDetails.title : ""}
      loading={isLoading || !eventDetails}
    >
      {isSuccess && eventDetails && (
        <Stack spacing={2}>
          <Typography variant="titleMedium">
            <div
              dangerouslySetInnerHTML={{ __html: eventDetails.description }}
            />
          </Typography>

          <Box>
            <Box sx={{ ml: 1, my: 1 }}>
              <DossierStatusStepper
                dossierStatus={dossierStatus.data}
              ></DossierStatusStepper>
            </Box>
          </Box>

          {dossier.data.noteToDriver && (
            <Box>
              <AutoPilotAlert
                severity="info"
                message={dossier.data.noteToDriver}
                asHTML={true}
              />
            </Box>
          )}

          <Box
            sx={{
              pt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Button
              size="large"
              color={nextIsHome ? "primary" : "secondary"}
              variant="contained"
              disableElevation={true}
              startIcon={nextIsHome ? undefined : <PlayArrowOutlinedIcon />}
              onClick={() => onNext()}
              sx={{ color: "onPrimary.main" }}
            >
              {nextIsHome ? "Home" : "Next"}
            </Button>
          </Box>
        </Stack>
      )}
    </PageContainer>
  );
};

export default ActionConfirmation;
