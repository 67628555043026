import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { signPackagingAgreement } from "../../api/dossier/signPackagingAgreement";
import APCheckbox from "../../components/form/APCheckbox";
import APForm from "../../components/form/APForm";
import PageContainer from "../../components/PageContainer";
import {
  buildPackagingAgreementFilename,
  downloadFile,
} from "../../helpers/download";

type Inputs = {
  agree: boolean;
};

const PackagingAgreement = () => {
  const { dossierId } = useParams();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const { fetchWithAuth, getClientIP } = useApi();
  const { getScopedPageUrl } = useAuth();

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });

  if (!dossierId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id param is misisng");
  }
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    signPackagingAgreement(dossierId, {
      mutationOptions: {
        successNavPath: `/dossiers/${dossierId}/action-confirmation/100000005`,
      },
    });

  const dossier = getDossier(dossierId);

  if (dossier.isError) {
    throw dossier.error;
  }

  const isLoading = dossier.isLoading;
  const isSuccess = dossier.isSuccess;

  const onSubmit: SubmitHandler<Inputs> = async () => {
    const { ip } = await getClientIP();

    mutateAsyncUpdate({
      driverIp: ip,
      driverDevice: navigator.userAgent,
    });
  };

  useEffect(() => {
    if (isSuccess && dossier.data.opportunity) {
      // If employer has not executed the packaging agreement then redirect to no action required page
      if (
        dossier.data.opportunity.employerExecutedPackagingAgreement === false
      ) {
        navigate(
          getScopedPageUrl("dossierActionConfirmation", dossierId, "not-ready"),
          {
            replace: true,
          }
        );
        // If driver has already executed the packaging agreement then redirect to dossier
      } else if (
        dossier.data.opportunity.driverExecutedPackagingAgreement === true
      ) {
        navigate(getScopedPageUrl("viewDossier", dossierId), {
          replace: true,
        });
      } else {
        setNavigationCheck(true);
      }
    }
  }, [isSuccess]);

  const downloadPDF = async () => {
    if (dossier.isSuccess) {
      const filename = buildPackagingAgreementFilename(dossier.data.identity);

      const pdf = await fetchWithAuth<Blob>(
        "getPackagingAgreementPDF",
        dossierId,
        { responseOptions: { responseType: "blob" } }
      );
      downloadFile(pdf, filename);
    }
  };

  return (
    <PageContainer
      loading={isLoading || !navigationCheckComplete}
      title="Packaging Agreement"
    >
      {isSuccess && (
        <Stack spacing={2}>
          <Typography variant="labelLarge">
            Agree to the Packaging Agreement so we can setup your car and
            request finance approval.
          </Typography>
          <APForm
            onSubmit={handleSubmit(onSubmit)}
            submitText="Next"
            submitIcon={<PlayArrowOutlined />}
            isLoading={isLoadingUpdate}
            isError={Object.keys(errors).length > 0}
          >
            <Box>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={() => downloadPDF()}
              >
                Auto-UX Packaging Agreement
              </Button>
            </Box>

            <Box>
              <Box>
                <APCheckbox
                  name="agree"
                  control={control}
                  label="I agree to the Packaging Agreement."
                  defaultValue={false}
                  validations={{ required: true }}
                  errors={errors}
                />
              </Box>
            </Box>
          </APForm>
        </Stack>
      )}
    </PageContainer>
  );
};

export default PackagingAgreement;
